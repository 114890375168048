@media (max-width: 1000px)

	ul#menu
		display: none !important


	#menu-mobile
		display: block

	.menu-item
		a
			&:hover,
			&.menu-active
				&:before
					left: -15px !important
					top: -13px !important
					width: 30px !important
					height: 30px !important

	.burger-icon
		display: block
		cursor: pointer
		-webkit-transition: all 300ms ease
		transition: all 300ms ease

		&:hover
			.burger-bar
				background: rgb(25, 97, 138)

				&:after,
				&:before
					background: rgb(25, 97, 138)

	.x
		.burger-bar
			background: transparent !important
			width: 0 !important

			&:before
				top: 0 !important
				left: -12px
				-webkit-transform: rotate(-45deg)
				transform: rotate(-45deg)

			&:after
				top: 0 !important
				left: -12px
				-webkit-transform: rotate(45deg)
				transform: rotate(45deg)

	ul#menu-drop
		display: none
		position: absolute
		background: $grau
		width: 100%
		left: 0
		top: 77px
		padding: 50px 30px
		-ms-flex-wrap: wrap
		flex-wrap: wrap

	nav
		padding: 15px 20px
		top: 0
		-webkit-transition: all 300ms ease
		transition: all 300ms ease

	.nav-bg
		opacity: 1

	#logo
		width: 123px !important
		height: 50px !important

	#burger
		-webkit-transition: all 300ms ease
		transition: all 300ms ease

	.drop
		display: -webkit-box !important
		display: -ms-flexbox !important
		display: flex !important
		top: 77px !important
		padding: 50px 30px !important
		-ms-flex-wrap: wrap
		flex-wrap: wrap
		-webkit-transition: all 300ms ease
		transition: all 300ms ease

	.menu-item
		width: 100%
		text-align: right
		padding: 15px 0

	.content
		padding: 0 20px

	.right
		text-align: center

	.text-wrapper
		max-width: 100%
		width: 100%

	.pt150
		padding-top: 100px

	.ptb200
		padding: 100px 30px !important

	.p100
		padding: 30px

	h1,
	h2
		font-size: 1.7em
		line-height: 1.1
		text-transform: uppercase
		letter-spacing: 0.05rem
		font-weight: 400

	.center .glas
		min-width: 25%

		p
			font-size: 0.825rem

	section#glaeser
		.flex.m50
			-webkit-box-orient: vertical
			-webkit-box-direction: normal
			-ms-flex-direction: column
			flex-direction: column
			display: block

		.logo
			min-width: 100px
			width: 25%
			max-width: 150px

	#qualitaet
		.w100
			width: 100%
			display: -webkit-box
			display: -ms-flexbox
			display: flex
			-webkit-box-pack: justify
			-ms-flex-pack: justify
			justify-content: space-between
			-ms-flex-wrap: wrap
			flex-wrap: wrap

			.glas
				width: calc(100% / 6.01)
				text-align: center
				float: none
				padding: 5px
				min-width: 100px

				p
					font-size: 0.825rem
@media (max-width: 674px)

	

	.flex-item
		margin: 0 !important
		padding: 0 !important
		min-width: 100%
	#qualitaet
		.w100
			width: 100%
			display: -webkit-box
			display: -ms-flexbox
			display: flex
			-webkit-box-pack: justify
			-ms-flex-pack: justify
			justify-content: space-between
			-ms-flex-wrap: wrap
			flex-wrap: wrap

			.glas
				width: 33% !important
				text-align: center
				float: none
				padding: 5px
				min-width: 25%

				p
					font-size: 0.825rem

	.order-1
		-webkit-box-ordinal-group: 0
		-ms-flex-order: -1
		order: -1

		.iframe-wrapper
			margin-bottom: 30px

	#header-content
		img
			width: 50%
			height: 50%

	.mt-40
		margin-top: 10px
		margin-bottom: 20px
		padding-bottom: 30vw

	.logo
		min-width: 40% !important
		max-width: none !important
		padding: 10px !important
		margin: 0 !important

	.block
		display: block