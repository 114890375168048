@charset "UTF-8";
@font-face {
  font-family: "Champagne Limousines";
  src: url("../fonts/Champagne_Limousines.eot");
  src: local("☺"), url("../fonts/Champagne_Limousines.woff") format("woff"), url("../fonts/Champagne_Limousines.ttf") format("truetype"), url("../fonts/Champagne_Limousines.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Champagne Limousines";
  src: url("../fonts/Champagne_Limousines-Thick.eot");
  src: local("☺"), url("../fonts/Champagne_Limousines-Thick.woff") format("woff"), url("../fonts/Champagne_Limousines-Thick.ttf") format("truetype"), url("../fonts/Champagne_Limousines-Thick.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Champagne Limousines";
  src: url("../fonts/Champagne_LimousinesBold.eot");
  src: local("☺"), url("../fonts/Champagne_LimousinesBold.woff") format("woff"), url("../fonts/Champagne_LimousinesBold.ttf") format("truetype"), url("../fonts/Champagne_LimousinesBold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
* {
  font-family: "Champagne Limousines";
  font-size: 14px;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
}

h1, h2 {
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 400;
}

p, a, ul, strong, li {
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05rem;
  line-height: 1.5;
}

strong {
  font-size: inherit;
  font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Champagne Limousines";
  font-size: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input {
  background-color: rgba(255, 255, 255, 0) !important;
}

body, html {
  width: 100%;
  height: 100%;
}

ul, ul li {
  list-style: none;
}

a, p {
  text-decoration: none;
  font-size: 1.1rem;
}

input[type=range] {
  -webkit-appearance: none;
  border: 1px solid transparent;
  /*required for proper track sizing in FF*/
}

input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: white;
  margin-top: -12px;
  border: 1px solid #093A5F;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]::-moz-range-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: white;
  border: 1px solid #093A5F;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring {
  outline: 1px solid transparent;
}

input[type=range]::-ms-track {
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type=range]::-ms-thumb {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #093A5F;
  margin-top: 4px;
}

input[type=range]:focus::-ms-fill-lower {
  background: #888;
}

input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}

#slider-body {
  overflow: visible !important;
}

* {
  scroll-behavior: smooth;
}

#menu-mobile {
  display: none;
}
#menu-mobile .menu-item {
  margin: 0 1vw;
}
#menu-mobile .menu-item a {
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  color: white;
  font-weight: 400;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
#menu-mobile .menu-item a:before {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 50%;
  width: 0px;
  height: 0px;
  border-bottom: none;
  border-right: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
#menu-mobile .menu-item a:hover, #menu-mobile .menu-item a.menu-active {
  color: #dce5ec;
  margin-right: 10px;
}
#menu-mobile .menu-item a:hover:before, #menu-mobile .menu-item a.menu-active:before {
  left: -15px;
  top: -23px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-bottom: none;
  border-right: none;
  width: 50px;
  height: 50px;
  z-index: 0;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
#menu-mobile .burger {
  display: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
#menu-mobile .burger-icon {
  width: 40px;
  height: 40px;
  background: white;
  position: relative;
}
#menu-mobile .burger-icon .burger-bar {
  position: absolute;
  width: 24px;
  height: 2px;
  background: #093A5F;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform-origin: center;
  transform-origin: center;
}
#menu-mobile .burger-icon .burger-bar:before {
  position: absolute;
  content: "";
  width: 24px;
  height: 2px;
  background: #093A5F;
  top: -8px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform-origin: center;
  transform-origin: center;
}
#menu-mobile .burger-icon .burger-bar:after {
  position: absolute;
  content: "";
  width: 24px;
  height: 2px;
  background: #093A5F;
  top: 8px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform-origin: center;
  transform-origin: center;
}
#menu-mobile.active #menu-drop {
  display: block;
}

.nav-bg {
  position: fixed;
  height: 77px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(5, 33, 55, 0.95)), to(rgba(9, 58, 95, 0.95)));
  background: linear-gradient(rgba(5, 33, 55, 0.95), rgba(9, 58, 95, 0.95));
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: 9999999999999;
}

nav {
  width: 100%;
  z-index: 10000000000000000;
  position: fixed;
  max-width: 1400px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  margin: 0px auto 30px auto;
  padding: 15px;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
nav #logo {
  width: 244px;
  height: 99px;
  display: block;
  position: relative;
}
nav #menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
nav #menu .menu-item {
  margin: 0 1vw;
}
nav #menu .menu-item a {
  text-transform: uppercase;
  position: relative;
  font-size: 1rem;
  color: white;
  font-weight: 400;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
nav #menu .menu-item a:before {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 50%;
  width: 0px;
  height: 0px;
  border-bottom: none;
  border-right: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}
nav #menu .menu-item a:hover, nav #menu .menu-item a.menu-active {
  color: #dce5ec;
  margin-right: 0px;
}
nav #menu .menu-item a:hover:before, nav #menu .menu-item a.menu-active:before {
  left: -15px;
  top: -23px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-bottom: none;
  border-right: none;
  width: 50px;
  height: 50px;
  z-index: 0;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.image {
  width: 100%;
}

.header {
  background: -webkit-gradient(linear, left top, left bottom, from(#6c7a87), to(#dce5ec));
  background: linear-gradient(to bottom, #6c7a87 0%, #dce5ec 100%);
  width: 100%;
  min-height: 18vw;
}
.header img {
  position: relative;
}

.content {
  width: 100%;
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
}

.center {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.center .glas {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 150px;
  margin: 10px;
  min-width: 100px;
}
.center .glas img {
  width: 100%;
}
.center .glas p {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 10px;
}

.top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.divider {
  position: relative;
  height: 50px;
  width: 100%;
}

.mt180 {
  margin-top: 180px;
}

.mt100 {
  margin-top: 100px;
}

.mt50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: -40px;
}

.mtb30 {
  margin: 30px 0;
}

.mb100 {
  margin-bottom: 100px 10%;
}

.p100 {
  padding: 100px;
}

.ptb200 {
  padding: 200px 0;
}

.pt150 {
  padding-top: 150px;
}

.m50 {
  margin: 50px auto;
}

.m30 {
  margin: 30px auto;
}

.c-blau {
  color: #093A5F;
}

.blau {
  background: #093A5F;
}

.black {
  background: black;
}

#qualitaet .content .flex .flex-item p {
  max-width: 570px;
}
#qualitaet .w100 {
  width: 100%;
}
#qualitaet .w100 .glas {
  width: 16.6389351082%;
  text-align: center;
  float: left;
  padding: 10px;
}
#qualitaet .w100 .glas img {
  width: 100%;
}
#qualitaet .w100 .clearfix {
  clear: both;
}
#qualitaet .iframe-wrapper {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}
#qualitaet .iframe-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
#qualitaet .flex .flex-item {
  margin: 0;
  padding: 0;
}
#qualitaet .flex .flex-item:first-child {
  padding-right: 30px;
}

.text-wrapper {
  width: 60%;
  max-width: 600px;
}

.right {
  text-align: right;
}

#qualitaet,
#vorteile,
#glaeser {
  position: relative;
}
#qualitaet h2,
#vorteile h2,
#glaeser h2 {
  color: white;
  text-align: left;
}
#qualitaet p,
#vorteile p,
#glaeser p {
  color: white;
}

.welle {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  margin: 0;
  top: 0;
}
.welle .welle-oben {
  width: 102%;
  height: 100%;
  min-width: 780px;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.welle-unten {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
}
.welle-unten img {
  width: 102%;
  height: 100%;
  min-width: 780px;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex .flex-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px 20px 10px 0;
}

.zitat {
  font-size: 1.2rem;
}

.cta,
input[type=submit] {
  cursor: pointer;
  background: steelblue !important;
  color: white;
  padding: 15px 30px;
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  margin: 20px 0;
  border-radius: 50px;
  text-align: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  position: relative;
  top: 0;
  left: 0;
  border: none !important;
  -webkit-box-shadow: 2px 3px 0 #38678f, inset 0 2px 2px rgba(255, 255, 255, 0.5);
  box-shadow: 2px 3px 0 #38678f, inset 0 2px 2px rgba(255, 255, 255, 0.5);
}
@media (min-width: 768px) {
  .cta,
  input[type=submit] {
    font-size: 16px;
  }
}
.cta:hover,
input[type=submit]:hover {
  background: #699bc4;
  -webkit-box-shadow: 0px 0px 0 #38678f, inset 0 2px 2px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 0 #38678f, inset 0 2px 2px rgba(255, 255, 255, 0.5);
  top: 3px;
  left: 2px;
}

.main__button.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 0;
}

.cta__container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

section#vorteile .item-1 {
  background: url(../img/brillenglaeser.png) center no-repeat;
  background-size: contain;
  width: 100%;
  height: 80%;
}
section#vorteile .item-2 {
  width: 100%;
  height: 20%;
}
section#vorteile .item-2 img {
  width: 225px;
  height: 81.75px;
}
section#vorteile .flex .flex-item:first-child {
  padding-right: 30px;
}
section#vorteile .flex .flex-item {
  margin: 0;
}
section#vorteile h2 {
  text-transform: none;
}

section#glaeser #logos {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
section#glaeser .h420 {
  min-height: 420px;
}
section#glaeser h2 {
  text-transform: none;
}
section#glaeser .w100 {
  width: 100%;
}
section#glaeser .logo {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 150px;
  margin: 0px 30px 0px 0;
  padding: 15px 15px 15px 0;
}
section#glaeser .logo img {
  width: 100%;
  height: auto;
}
section#glaeser .item-1 {
  width: 100%;
  position: relative;
}
section#glaeser .item-1 .glas {
  max-width: 250px;
  position: relative;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  display: none;
}
section#glaeser .item-1 .glas.active {
  display: block;
}
section#glaeser .slider {
  margin-top: 60px;
  width: 100%;
  text-align: center;
}
section#glaeser .slider #slider {
  width: 80%;
  margin: 0 auto;
}
section#glaeser .slider label {
  color: white;
  font-size: 1.1rem;
}
section#glaeser .slider label .flex {
  width: 80%;
  margin: 20px auto;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#glaeser ul.flex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

#glaeser ul.flex li {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20%;
  flex: 1 0 20%;
}
#glaeser ul.flex li .logo {
  min-width: 100px;
  margin: 0;
  width: 100%;
  padding: 5px;
}

@media (max-width: 540px) {
  #glaeser ul.flex {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #glaeser ul.flex li {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 40%;
    flex: 1 0 40%;
  }
}
#einkauf {
  text-align: center;
}
#einkauf h2 {
  text-transform: none;
}
#einkauf img#einkaufswagen {
  width: 216px;
  height: 216px;
}

#service h2,
#service p {
  text-transform: none;
  color: white;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

footer {
  width: 100%;
  background: #243341;
  color: white;
  padding: 20px 10px;
}
footer .footer-1 {
  text-transform: uppercase;
}
footer a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
footer a:hover {
  color: #6c7a87;
}

#impressum h2,
#impressum p,
#impressum a {
  color: white;
}

@media (max-width: 1000px) {
  ul#menu {
    display: none !important;
  }
  #menu-mobile {
    display: block;
  }
  .menu-item a:hover:before, .menu-item a.menu-active:before {
    left: -15px !important;
    top: -13px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .burger-icon {
    display: block;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .burger-icon:hover .burger-bar {
    background: rgb(25, 97, 138);
  }
  .burger-icon:hover .burger-bar:after, .burger-icon:hover .burger-bar:before {
    background: rgb(25, 97, 138);
  }
  .x .burger-bar {
    background: transparent !important;
    width: 0 !important;
  }
  .x .burger-bar:before {
    top: 0 !important;
    left: -12px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .x .burger-bar:after {
    top: 0 !important;
    left: -12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ul#menu-drop {
    display: none;
    position: absolute;
    background: #243341;
    width: 100%;
    left: 0;
    top: 77px;
    padding: 50px 30px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  nav {
    padding: 15px 20px;
    top: 0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .nav-bg {
    opacity: 1;
  }
  #logo {
    width: 123px !important;
    height: 50px !important;
  }
  #burger {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .drop {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    top: 77px !important;
    padding: 50px 30px !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .menu-item {
    width: 100%;
    text-align: right;
    padding: 15px 0;
  }
  .content {
    padding: 0 20px;
  }
  .right {
    text-align: center;
  }
  .text-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .pt150 {
    padding-top: 100px;
  }
  .ptb200 {
    padding: 100px 30px !important;
  }
  .p100 {
    padding: 30px;
  }
  h1,
  h2 {
    font-size: 1.7em;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 400;
  }
  .center .glas {
    min-width: 25%;
  }
  .center .glas p {
    font-size: 0.825rem;
  }
  section#glaeser .flex.m50 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: block;
  }
  section#glaeser .logo {
    min-width: 100px;
    width: 25%;
    max-width: 150px;
  }
  #qualitaet .w100 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #qualitaet .w100 .glas {
    width: 16.6389351082%;
    text-align: center;
    float: none;
    padding: 5px;
    min-width: 100px;
  }
  #qualitaet .w100 .glas p {
    font-size: 0.825rem;
  }
}
@media (max-width: 674px) {
  .flex-item {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 100%;
  }
  #qualitaet .w100 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #qualitaet .w100 .glas {
    width: 33% !important;
    text-align: center;
    float: none;
    padding: 5px;
    min-width: 25%;
  }
  #qualitaet .w100 .glas p {
    font-size: 0.825rem;
  }
  .order-1 {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-1 .iframe-wrapper {
    margin-bottom: 30px;
  }
  #header-content img {
    width: 50%;
    height: 50%;
  }
  .mt-40 {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 30vw;
  }
  .logo {
    min-width: 40% !important;
    max-width: none !important;
    padding: 10px !important;
    margin: 0 !important;
  }
  .block {
    display: block;
  }
}
.r-content {
  flex: 2;
  min-width: 260px;
}
.r-content h2 {
  font-size: 1.5rem;
}

.l-content {
  flex: 1;
  min-width: 260px;
  margin-right: 30px;
  margin-bottom: 30px;
}

#impressum-head .content {
  text-align: center;
}

@media (max-width: 640px) {
  #impressum-head .content img {
    width: 80%;
    height: 80%;
  }
}
.header-blue {
  background: #093A5F;
  width: 100%;
  min-height: 18vw;
  color: white !important;
  padding-top: 200px !important;
}
.header-blue .image-wrapper {
  width: 50%;
  background: url(../img/online-store.png) center no-repeat;
  background-size: contain;
  min-height: 250px;
  margin-bottom: 30px;
}
.header-blue .image-wrapper img {
  width: 100%;
}
.header-blue img {
  position: relative;
}

#einkauf-form {
  background: white;
  position: relative;
}
#einkauf-form .content {
  max-width: 900px;
  margin: 0 auto;
}
#einkauf-form form .einkaufsgruppe--row {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#einkauf-form form .einkaufsgruppe--row .input--group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2rem;
}
#einkauf-form form .einkaufsgruppe--row .input--group:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  margin-right: 0;
}
#einkauf-form form .einkaufsgruppe--row .input--group #einkaufsgruppe {
  display: none;
  margin: 0 0 0 2rem;
}
#einkauf-form form .einkaufsgruppe--row .input--group input[type=radio] {
  margin-right: 0.5rem;
}
#einkauf-form form .einkaufsgruppe--row .input--group input[type=radio]:checked ~ #einkaufsgruppe {
  display: block;
}
#einkauf-form form input[type=text], #einkauf-form form input[type=textarea] {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid rgba(36, 51, 65, 0.3);
  background: white;
  margin-bottom: 20px;
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .header-blue {
    padding-top: 100px !important;
  }
}
@media (max-width: 640px) {
  #einkauf-form form .einkaufsgruppe--row {
    display: block;
  }
  #einkauf-form form .einkaufsgruppe--row .input--group {
    margin-bottom: 1rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #einkauf-form form .einkaufsgruppe--row .input--group #einkaufsgruppe {
    margin: 0;
    margin-top: 1rem;
  }
}
.bestaetigung {
  background: #093A5F;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bestaetigung .confirmationContent {
  text-align: center !important;
  max-width: 600px;
  width: 100%;
  padding: 60px 50px;
  border: 1px solid #1273bc;
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}
.bestaetigung .confirmationContent h1 {
  font-size: 1.6em;
  color: #093A5F;
  text-align: center !important;
}
.bestaetigung .confirmationContent #phone-icn {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
}
.bestaetigung .confirmationContent .fill {
  fill: #093A5F;
}

@media (max-width: 600px) {
  .bestaetigung {
    display: block;
    padding: 10px;
  }
}
#presse h2 {
  color: white;
  text-align: center;
}

#downloads {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}
#downloads .beitrag {
  max-width: 300px;
  height: 100%;
  margin: 30px auto;
}
#downloads .beitrag img {
  max-height: 400px;
  max-width: 270px;
}
#downloads .beitrag .download-btn {
  padding: 9px;
  color: white;
}

.modal {
  background: rgba(36, 51, 65, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 100000000000000000000;
}
.modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 0;
}
.modal .modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #093A5F;
  color: white;
  z-index: 10;
  padding: 10px;
  border: 1px solid white;
}
.modal .modal__close img {
  position: relative;
  pointer-events: none;
}

.glas__slider .swiper-wrapper .swiper-slide {
  padding: 0 60px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 40px;
}
@media (max-width: 768px) {
  .glas__slider .swiper-wrapper .swiper-slide {
    padding: 0 0 40px 0;
  }
}
@media (min-width: 768px) {
  .glas__slider .swiper-wrapper .swiper-slide {
    grid-template-columns: auto 1fr;
  }
}
.glas__slider .swiper-wrapper .swiper-slide .slider__image {
  width: 400px;
  max-width: 70vw;
}
@media (min-width: 768px) {
  .glas__slider .swiper-wrapper .swiper-slide .slider__image {
    max-width: 30vw;
  }
}
.glas__slider .swiper-wrapper .swiper-slide .slider__image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.glas__slider .swiper-wrapper .swiper-slide .slider__content .slider__logo {
  margin-bottom: 20px;
}
.glas__slider .swiper-wrapper .swiper-slide .slider__content .slider__text {
  color: white;
  line-height: 1.5;
  max-width: 500px;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  padding: 10px 2px 10px;
}
.glas__slider .swiper-wrapper .swiper-slide .slider__content button {
  margin-top: 40px;
}
.glas__slider .swiper-button-next,
.glas__slider .swiper-button-prev {
  width: 40px;
}
@media (max-width: 768px) {
  .glas__slider .swiper-button-next,
  .glas__slider .swiper-button-prev {
    bottom: 0;
    top: auto;
  }
}
.glas__slider .swiper-button-next img,
.glas__slider .swiper-button-prev img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.glas__slider .swiper-button-next:after,
.glas__slider .swiper-button-prev:after {
  content: none;
}