.header-blue
	background: $blau
	width: 100%
	min-height: 18vw
	color: white !important
	padding-top: 200px !important
	.image-wrapper
		width: 50%
		background: url(../img/online-store.png) center no-repeat
		background-size: contain
		min-height: 250px
		margin-bottom: 30px
		img
			width: 100%
	img
		position: relative
#einkauf-form
	background: white
	position: relative
	.content
		max-width: 900px
		margin: 0 auto
	form
		.einkaufsgruppe--row
			margin-bottom: 2rem
			display: -webkit-box
			display: -ms-flexbox
			display: flex
			-webkit-box-align: center
			-ms-flex-align: center
			align-items: center
			.input--group
				display: -webkit-box
				display: -ms-flexbox
				display: flex
				-webkit-box-align: center
				-ms-flex-align: center
				align-items: center
				margin-right: 2rem
				&:last-child
					-webkit-box-flex: 1
					-ms-flex: 1 0 0px
					flex: 1 0 0
					margin-right: 0
				#einkaufsgruppe
					display: none
					margin: 0 0 0 2rem
				input[type="radio"]
					margin-right: 0.5rem
					&:checked ~ #einkaufsgruppe
						display: block
		input[type="text"], input[type="textarea"]
			width: 100%
			padding: 10px 20px
			font-size: 16px
			border: 1px solid rgba($grau, 0.3)
			background: white
			margin-bottom: 20px
			border-radius: 4px
@media (max-width: 1000px)
	.header-blue
		padding-top: 100px !important
@media (max-width: 640px)
	#einkauf-form
		form
			.einkaufsgruppe--row
				display: block
				.input--group
					margin-bottom: 1rem
					-ms-flex-wrap: wrap
					flex-wrap: wrap
					#einkaufsgruppe
						margin: 0
						margin-top: 1rem
