input[type=range]
	-webkit-appearance: none
	border: 1px solid transparent
		/*required for proper track sizing in FF*/
input[type=range]::-webkit-slider-runnable-track

	height: 5px
	background: #ddd
	border: none
	border-radius: 3px
input[type=range]::-webkit-slider-thumb
	-webkit-appearance: none
	border: none
	height: 30px
	width: 30px
	border-radius: 50%
	background: white
	margin-top: -12px
	border: 1px solid $blau
input[type=range]:focus
	outline: none
input[type=range]:focus::-webkit-slider-runnable-track
	background: #ccc

input[type=range]::-moz-range-track
	height: 5px
	background: #ddd
	border: none
	border-radius: 3px
input[type=range]::-moz-range-thumb
	border: none
	height: 30px
	width: 30px
	border-radius: 50%
	background: white
	border: 1px solid $blau

/*hide the outline behind the border*/
input[type=range]:-moz-focusring
	outline: 1px solid transparent

input[type=range]::-ms-track
	height: 5px

	/*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
	background: transparent

	/*leave room for the larger thumb to overflow with a transparent border */
	border-color: transparent
	border-width: 6px 0

	/*remove default tick marks*/
	color: transparent
input[type=range]::-ms-fill-lower
	background: #777
	border-radius: 10px
input[type=range]::-ms-fill-upper
	background: #ddd
	border-radius: 10px
input[type=range]::-ms-thumb
	border: none
	height: 20px
	width: 20px
	border-radius: 50%
	background: white
	border: 1px solid $blau
	margin-top: 4px
input[type=range]:focus::-ms-fill-lower
	background: #888
input[type=range]:focus::-ms-fill-upper
	background: #ccc

#slider-body
	overflow: visible !important