.r-content
	flex: 2
	min-width: 260px
	h2
		font-size: 1.5rem

.l-content
	flex: 1
	min-width: 260px
	margin-right: 30px
	margin-bottom: 30px

#impressum-head
	.content
		text-align: center

@media (max-width: 640px)
	#impressum-head
		.content
			img
				width: 80%
				height: 80%
