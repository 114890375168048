*
	scroll-behavior: smooth
#menu-mobile
	display: none
	.menu-item
		margin: 0 1vw
		a
			text-transform: uppercase
			position: relative
			font-size: 1.0rem
			color: white
			font-weight: 400
			-webkit-transition: all 150ms ease
			transition: all 150ms ease
			&:before
				content: ""
				z-index: -1
				position: absolute
				left: 0px
				top: 0px
				border: 2px solid rgba(#ffffff, 0.0)
				border-radius: 50%
				width: 0px
				height: 0px
				border-bottom: none
				border-right: none
				-webkit-transition: all 150ms ease
				transition: all 150ms ease
				-webkit-transform: rotate(0deg)
				transform: rotate(0deg)
				background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.5)), to(rgba(255,255,255,0.0)))
				background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.0))
			&:hover,
			&.menu-active
				color: $hgrau
				margin-right: 10px

				&:before
					left: -15px
					top: -23px
					border: 2px solid rgba(#ffffff, 0.5)
					border-radius: 50%
					border-bottom: none
					border-right: none
					width: 50px
					height: 50px
					z-index: 0
					-webkit-transform: rotate(-10deg)
					transform: rotate(-10deg)
					background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.5)), to(rgba(255,255,255,0.0)))
					background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.0))
	.burger
		display: none
		-webkit-transition: all 300ms ease
		transition: all 300ms ease

	.burger-icon
		width: 40px
		height: 40px
		background: white
		position: relative
		.burger-bar
			position: absolute
			width: 24px
			height: 2px
			background: $blau
			top: 50%
			left: 50%
			-webkit-transform: translate(-50%,-50%)
			transform: translate(-50%,-50%)
			-webkit-transition: all 300ms ease
			transition: all 300ms ease
			-webkit-transform-origin: center
			transform-origin: center
			&:before
				position: absolute
				content: ""
				width: 24px
				height: 2px
				background: $blau
				top: -8px
				-webkit-transition: all 300ms ease
				transition: all 300ms ease
				-webkit-transform-origin: center
				transform-origin: center
			&:after
				position: absolute
				content: ""
				width: 24px
				height: 2px
				background: $blau
				top: 8px
				-webkit-transition: all 300ms ease
				transition: all 300ms ease
				-webkit-transform-origin: center
				transform-origin: center
	&.active
		#menu-drop
			display: block

.nav-bg
	position: fixed
	height: 77px
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(#052137, 0.95)), to(rgba($blau, 0.95)))
	background: linear-gradient(rgba(#052137, 0.95), rgba($blau, 0.95))
	left: 0
	right: 0
	top: 0
	opacity: 0
	z-index: 9999999999999



nav
	width: 100%
	z-index: 9999999999999999
	position: fixed
	max-width: 1400px
	left: 50%
	-webkit-transform: translate(-50%)
	transform: translate(-50%)
	margin: 0px auto 30px auto
	padding: 15px
	top: 30px
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-webkit-box-pack: justify
	-ms-flex-pack: justify
	justify-content: space-between
	-webkit-box-align: center
	-ms-flex-align: center
	align-items: center



	#logo
		width: 244px
		height: 99px
		display: block
		position: relative
	#menu
		display: -webkit-box
		display: -ms-flexbox
		display: flex
		-webkit-box-pack: end
		-ms-flex-pack: end
		justify-content: flex-end

		.menu-item
			margin: 0 1vw
			a
				text-transform: uppercase
				position: relative
				font-size: 1.0rem
				color: white
				font-weight: 400
				-webkit-transition: all 150ms ease
				transition: all 150ms ease
				&:before
					content: ""
					z-index: -1
					position: absolute
					left: 0px
					top: 0px
					border: 2px solid rgba(#ffffff, 0.0)
					border-radius: 50%
					width: 0px
					height: 0px
					border-bottom: none
					border-right: none
					-webkit-transition: all 150ms ease
					transition: all 150ms ease
					-webkit-transform: rotate(0deg)
					transform: rotate(0deg)
					background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.5)), to(rgba(255,255,255,0.0)))
					background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.0))
				&:hover,
				&.menu-active
					color: $hgrau
					margin-right: 0px

					&:before
						left: -15px
						top: -23px
						border: 2px solid rgba(#ffffff, 0.5)
						border-radius: 50%
						border-bottom: none
						border-right: none
						width: 50px
						height: 50px
						z-index: 0
						-webkit-transform: rotate(-10deg)
						transform: rotate(-10deg)
						background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.5)), to(rgba(255,255,255,0.0)))
						background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.0))



// /*=================================
// Layout
// =================================*/


.image
	width: 100%

.header
	background: -webkit-gradient(linear, left top, left bottom, from($mgrau), to($hgrau))
	background: linear-gradient(to bottom, $mgrau 0%, $hgrau 100%)
	width: 100%

	min-height: 18vw
	img
		position: relative


.content
	width: 100%
	max-width: 1400px
	position: relative
	margin: 0 auto

.center
	-ms-flex-wrap: wrap
	flex-wrap: wrap
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-webkit-box-align: center
	-ms-flex-align: center
	align-items: center
	-webkit-box-pack: center
	-ms-flex-pack: center
	justify-content: center
	.glas
		-webkit-box-flex: 1
		-ms-flex: 1
		flex: 1
		max-width: 150px
		margin: 10px
		min-width: 100px
		img
			width: 100%
		p
			text-align: center
			font-size: 1.1rem
			margin-top: 10px

.top
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-webkit-box-pack: center
	-ms-flex-pack: center
	justify-content: center
	-ms-flex-wrap: wrap
	flex-wrap: wrap

.space-between
	-webkit-box-pack: justify
	-ms-flex-pack: justify
	justify-content: space-between

.divider
	position: relative
	height: 50px
	width: 100%

.mt180
	margin-top: 180px
.mt100
	margin-top: 100px
.mt50
	margin-top: 50px
.mt-40
	margin-top: -40px
.mtb30
	margin: 30px 0
.mb100
	margin-bottom: 100px 10%

.p100
	padding: 100px
.ptb200
	padding: 200px 0
.pt150
	padding-top: 150px

.m50
	margin: 50px auto

.m30
	margin: 30px auto

.c-blau
	color: $blau

.blau
	background: $blau

.black
	background: black


// /*==============================
// qualitaet
// ==============================*/


#qualitaet
	.content .flex .flex-item p
		max-width: 570px
	.w100
		width: 100%
		.glas
			width: calc(100% / 6.01)
			text-align: center
			float: left
			padding: 10px

			img
				width: 100%
		.clearfix
			clear: both
	.iframe-wrapper
		width: 100%
		height: 0
		position: relative
		padding-bottom: 56.25%
		iframe
			position: absolute
			width: 100%
			height: 100%
	.flex
		.flex-item
			margin: 0
			padding: 0
			&:first-child
				padding-right: 30px
.text-wrapper
	width: 60%
	max-width: 600px

.right
	text-align: right

#qualitaet,
#vorteile,
#glaeser
	position: relative
	h2
		color: white
		text-align: left
	p
		color: white
.welle
	width: 100%
	height: 100px
	overflow: hidden
	position: relative
	margin: 0
	top: 0
	.welle-oben
		width: 102%
		height: 100%
		min-width: 780px
		position: relative
		left: 50%
		-webkit-transform: translate(-50%)
		transform: translate(-50%)
.welle-unten
	width: 100%
	height: 100px
	overflow: hidden
	position: relative
	// margin: -100px 0 -10px 0;
	// top: -7px;
	img
		width: 102%
		height: 100%
		min-width: 780px
		position: relative
		left: 50%
		-webkit-transform: translate(-50%)
		transform: translate(-50%)

.flex
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-ms-flex-wrap: wrap
	flex-wrap: wrap
	.flex-item
		-webkit-box-flex: 1
		-ms-flex: 1
		flex: 1

		margin: 10px 20px 10px 0

.zitat
	font-size: 1.2rem

// /*=================================
// CTA
// =================================*/



.cta,
input[type="submit"]
	cursor: pointer
	background: $cta !important
	color: white
	padding: 15px 30px
	display: inline-block
	font-weight: 800
	font-size: 14px
	@media(min-width: 768px)
		font-size: 16px
	text-transform: uppercase
	margin: 20px 0
	border-radius: 50px
	text-align: center
	-webkit-transition: all 200ms ease
	transition: all 200ms ease
	position: relative
	top: 0
	left: 0
	border: none !important
	-webkit-box-shadow: 2px 3px 0 darken($cta, 10%), inset 0 2px	2px rgba(255,255,255, 0.5)
	box-shadow: 2px 3px 0 darken($cta, 10%), inset 0 2px	2px rgba(255,255,255, 0.5)
	&:hover
		background: lighten($cta, 10%)
		-webkit-box-shadow: 0px 0px 0 darken($cta, 10%), inset 0 2px	2px rgba(255,255,255, 0.5)
		box-shadow: 0px 0px 0 darken($cta, 10%), inset 0 2px	2px rgba(255,255,255, 0.5)
		top: 3px
		left: 2px



.main__button.cta
	display: flex
	align-items: center
	justify-content: center
	gap: 10px
	letter-spacing: 1px
	margin-bottom: 0
	margin-top: 0
.cta__container
	display: flex
	align-items: center
	justify-content: start
	gap: 20px
	flex-wrap: wrap
	margin-top: 20px

// /*=================================
// vorteile
// =================================*/

section#vorteile

	.item-1
		background: url(../img/brillenglaeser.png) center no-repeat
		background-size: contain
		width: 100%
		height: 80%
	.item-2
		width: 100%
		height: 20%

		img
			width: calc(300px * 0.75)
			height: calc(109px * 0.75)
	.flex
		.flex-item:first-child
			padding-right: 30px
		.flex-item
			margin: 0



	h2
		text-transform: none

// /*=================================
// gläser
// =================================*/

section#glaeser
	#logos
		padding-top: 30px
		margin-top: 30px
		border-top: 1px solid rgba(white, 0.5)
	.h420
		min-height: 420px
	h2
		text-transform: none
	.w100
		width: 100%
	.logo

		-webkit-box-flex: 1
		-ms-flex: 1
		flex: 1
		min-width: 150px
		margin: 0px 30px 0px 0
		padding: 15px 15px 15px 0
		img
			width: 100%
			height: auto
	.item-1
		width: 100%
		position: relative
		.glas
			max-width: 250px
			position: relative
			top: 0
			left: 50%
			-webkit-transform: translate(-50%)
			transform: translate(-50%)
			display: none
			&.active
				display: block

	.slider
		margin-top: 60px
		width: 100%
		text-align: center
		#slider
			width: 80%
			margin: 0 auto
		label
			color: white
			font-size: 1.1rem
			.flex
				width: 80%
				margin: 20px auto

.align-end
	-webkit-box-align: end
	-ms-flex-align: end
	align-items: flex-end

#glaeser ul.flex
	-webkit-box-align: center
	-ms-flex-align: center
	align-items: center
	-ms-flex-wrap: nowrap
	flex-wrap: nowrap


#glaeser ul.flex li

	-webkit-box-flex: 1
	-ms-flex: 1 0 20%
	flex: 1 0 20%
	//min-width: 50px!important;
	.logo
		min-width: 100px
		margin: 0
		width: 100%
		padding: 5px

@media (max-width: 540px)
	#glaeser ul.flex
		-ms-flex-wrap: wrap
		flex-wrap: wrap
	#glaeser ul.flex li
		-webkit-box-flex: 1
		-ms-flex: 1 0 40%
		flex: 1 0 40%

// /*=================================
// Einkaufsvorteile
// =================================*/

#einkauf
	text-align: center
	h2
		text-transform: none
	img#einkaufswagen
		width: 216px
		height: 216px
// /*=================================
// Service
// =================================*/

#service
	h2,
	p
		text-transform: none
		color: white
		text-align: center
		max-width: 700px
		margin: 0 auto
// /*=================================
// Footer
// =================================*/

footer
	width: 100%
	background: $grau
	color: white
	padding: 20px 10px
	.footer-1
		text-transform: uppercase
	a
		text-decoration: none
		color: white
		margin: 0 10px
		-webkit-transition: all 300ms ease
		transition: all 300ms ease
		&:hover
			color: $mgrau
// /*=================================
// Impressum
// =================================*/

#impressum
	h2,
	p,
	a
		color: white