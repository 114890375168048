* 
	margin: 0
	padding: 0
	font-family: 'Champagne Limousines'
	font-size: 16px
	-webkit-box-sizing: border-box
	box-sizing: border-box

input
	background-color: rgba(255,255,255,0.0)!important

body, html
	width: 100%
	height: 100%

ul, ul li
	list-style: none

a, p
	text-decoration: none
	font-size: 1.1rem
