.modal
	background: rgba($grau, 0.9)
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	padding: 20px
	z-index: 99999999999999999999
	img
		width: 100%
		height: 100%
		object-fit: contain
		position: absolute
		z-index: 0

	.modal__close
		position: absolute
		top: 20px
		right: 20px
		border: none
		border-radius: 4px
		outline: none
		width: 40px
		height: 40px
		cursor: pointer
		display: flex
		align-items: center
		justify-content: center
		background: $blau
		color: white
		z-index: 10
		padding: 10px
		border: 1px solid white
		img
			position: relative
			pointer-events: none
			

