.glas__slider
	.swiper-wrapper
		.swiper-slide
			padding: 0 60px
			@media (max-width: 768px)
				padding: 0 0 40px 0
			display: grid
			justify-content: center
			@media (min-width: 768px)
				grid-template-columns: auto 1fr
			align-items: center
			grid-gap: 40px
			.slider__image
				width: 400px
				max-width: 70vw
				@media (min-width: 768px)
					max-width: 30vw
				img
					width: 100%
					height: auto
					object-fit: contain
			.slider__content
				.slider__logo
					margin-bottom: 20px
				.slider__text
					color: white
					line-height: 1.5
					max-width: 500px
					font-size: 1.1rem
					letter-spacing: 0.05rem
					padding: 10px 2px 10px
				@media (min-width: 768px)

					// padding-right: 60px
				button
					margin-top: 40px
	.swiper-button-next,
	.swiper-button-prev
		width: 40px
		@media (max-width: 768px)
			bottom: 0
			top: auto
		img
			width: 100%
			height: auto
			object-fit: contain
	.swiper-button-next:after,
	.swiper-button-prev:after
		content: none