#presse
	h2
		color: white
		text-align: center

#downloads
	display: flex
	max-width: 100%
	flex-wrap: wrap
	.beitrag
		max-width: 300px
		height: 100%
		margin: 30px auto
		img
			max-height: 400px
			max-width: 270px
		.download-btn
			padding: 9px
			color: white