@font-face
	font-family: 'Champagne Limousines'
	src: url('../fonts/Champagne_Limousines.eot')
	src: local('☺'), url('../fonts/Champagne_Limousines.woff') format('woff'), url('../fonts/Champagne_Limousines.ttf') format('truetype'), url('../fonts/Champagne_Limousines.svg') format('svg')
	font-weight: 400
	font-style: normal

@font-face
	font-family: 'Champagne Limousines'
	src: url('../fonts/Champagne_Limousines-Thick.eot')
	src: local('☺'), url('../fonts/Champagne_Limousines-Thick.woff') format('woff'), url('../fonts/Champagne_Limousines-Thick.ttf') format('truetype'), url('../fonts/Champagne_Limousines-Thick.svg') format('svg')
	font-weight: 800
	font-style: normal

@font-face
	font-family: 'Champagne Limousines'
	src: url('../fonts/Champagne_LimousinesBold.eot')
	src: local('☺'), url('../fonts/Champagne_LimousinesBold.woff') format('woff'), url('../fonts/Champagne_LimousinesBold.ttf') format('truetype'), url('../fonts/Champagne_LimousinesBold.svg') format('svg')
	font-weight: 600
	font-style: normal

*
	font-family: 'Champagne Limousines'
	font-size: 14px
	line-height: 1.1
	-webkit-font-smoothing: antialiased

h1, h2
	font-size: 2.5em
	text-transform: uppercase
	letter-spacing: 0.05rem
	font-weight: 400

p, a, ul, strong, li
	font-weight: 400
	font-style: normal
	letter-spacing: 0.05rem
	line-height: 1.5

strong
	font-size: inherit
	font-weight: 800

