.bestaetigung
	background: $blau
	min-height: 100vh
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	-webkit-box-pack: center
	-ms-flex-pack: center
	justify-content: center
	-webkit-box-align: center
	-ms-flex-align: center
	align-items: center
	.confirmationContent
		text-align: center!important
		max-width: 600px
		width: 100%
		//margin: 0 auto
		padding: 60px 50px
		border: 1px solid lighten($blau, 20%)
		background: white
		border-radius: 10px
		-webkit-box-shadow: 0 0 25px rgba(0,0,0, 0.25)
		box-shadow: 0 0 25px rgba(0,0,0, 0.25)
		h1
			font-size: 1.6em
			color: $blau
			text-align: center!important
		#phone-icn
			width: 80px
			height: 80px
			margin-bottom: 30px
		.fill
			fill: $blau

@media (max-width: 600px)
	.bestaetigung
		display: block
		padding: 10px
